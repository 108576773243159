var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        "xmlns:dc": "http://purl.org/dc/elements/1.1/",
        "xmlns:cc": "http://creativecommons.org/ns#",
        "xmlns:rdf": "http://www.w3.org/1999/02/22-rdf-syntax-ns#",
        "xmlns:svg": "http://www.w3.org/2000/svg",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:sodipodi": "http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd",
        "xmlns:inkscape": "http://www.inkscape.org/namespaces/inkscape",
        width: "35.999996",
        height: "36",
        viewBox: "0 0 35.999996 36",
        fill: "none",
        version: "1.1",
        id: "svg12",
        "sodipodi:docname": "icona-blog-salabam (1).svg",
        "inkscape:version": "1.0.2 (1.0.2+r75+1)",
      },
    },
    [
      _c(
        "metadata",
        { attrs: { id: "metadata18" } },
        [
          _c(
            "rdf:RDF",
            [
              _c(
                "cc:Work",
                { attrs: { "rdf:about": "" } },
                [
                  _c("dc:format", [_vm._v("image/svg+xml")]),
                  _c("dc:type", {
                    attrs: {
                      "rdf:resource": "http://purl.org/dc/dcmitype/StillImage",
                    },
                  }),
                  _c("dc:title"),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("defs", { attrs: { id: "defs16" } }),
      _c("sodipodi:namedview", {
        attrs: {
          pagecolor: "#ffffff",
          bordercolor: "#666666",
          borderopacity: "1",
          objecttolerance: "10",
          gridtolerance: "10",
          guidetolerance: "10",
          "inkscape:pageopacity": "0",
          "inkscape:pageshadow": "2",
          "inkscape:window-width": "1920",
          "inkscape:window-height": "1016",
          id: "namedview14",
          showgrid: "false",
          "fit-margin-top": "0",
          "fit-margin-left": "0",
          "fit-margin-right": "0",
          "fit-margin-bottom": "0",
          "inkscape:zoom": "9.4408602",
          "inkscape:cx": "18.078347",
          "inkscape:cy": "18.000027",
          "inkscape:window-x": "1920",
          "inkscape:window-y": "27",
          "inkscape:window-maximized": "1",
          "inkscape:current-layer": "svg12",
        },
      }),
      _c("path", {
        staticStyle: { "stroke-width": "0.387423" },
        attrs: {
          d: "M 33.210794,0.387097 C 32.825293,0.232908 32.430253,0.103609 32.028156,0 H 3.955048 C 3.51458,0.107464 3.081897,0.244551 2.659963,0.410323 1.98259,0.702391 1.39024,1.160788 0.938042,1.74285 0.485844,2.324912 0.188495,3.011723 0.07367,3.739357 0.05397,3.806867 0.02935,3.872867 0,3.936776 v 28.025823 c 0.06979,0.267097 0.131835,0.522581 0.209385,0.774194 0.296385,0.946607 0.897583,1.768995 1.710222,2.339498 0.812643,0.570465 1.791239,0.85711 2.783802,0.815343 h 12.512696 1.16325 0.23265 c 0.290813,-0.91742 0.589381,-2.016776 0.876316,-2.99226 H 4.753813 c -1.19427,0 -1.764263,-0.569033 -1.764263,-1.772904 V 6.406455 c 0,-0.127742 0.02326,-0.263226 0.02326,-0.387097 h 29.945947 v 12.561298 l 2.989553,2.51613 v -3.15484 c 0,-4.463229 0.03102,-8.910973 0,-13.36646 C 35.9789,3.681037 35.732449,2.798932 35.242566,2.049407 34.752682,1.299879 34.043177,0.719404 33.210794,0.387097 Z",
          fill: "#212d44",
          id: "path2",
        },
      }),
      _c("path", {
        staticStyle: { "stroke-width": "0.387423" },
        attrs: {
          d: "m 28.777414,24.171498 4.058891,4.018957 c -0.06301,0.06952 -0.135945,0.148955 -0.20892,0.221807 -1.989662,1.986272 -3.988126,3.972583 -5.995469,5.958893 -0.152774,0.151394 -0.339088,0.264775 -0.543859,0.331046 -1.415947,0.430374 -2.838563,0.844181 -4.264476,1.251368 -0.163087,0.05106 -0.336489,0.06019 -0.504036,0.02648 -0.06727,-0.0197 -0.129896,-0.05253 -0.184375,-0.09654 -0.05448,-0.04405 -0.09965,-0.0984 -0.13296,-0.159988 -0.03327,-0.06155 -0.05405,-0.129096 -0.06103,-0.198696 -0.007,-0.0696 -1.56e-4,-0.139897 0.0202,-0.206865 0.442152,-1.544904 0.892019,-3.077653 1.349642,-4.598287 0.03955,-0.11218 0.105507,-0.213251 0.192363,-0.294619 2.069228,-2.081227 4.138457,-4.151383 6.207685,-6.210507 0.01908,-0.01858 0.04161,-0.03321 0.06634,-0.04305 z m -3.080635,9.441567 c -0.119389,-0.632323 -0.232146,-1.23813 -0.354831,-1.837356 -0.0096,-0.03918 -0.02803,-0.07564 -0.0539,-0.106607 -0.02586,-0.03097 -0.05847,-0.05566 -0.09535,-0.07216 -0.606829,-0.135716 -1.220288,-0.258193 -1.843713,-0.384 -0.225515,0.768039 -0.421174,1.509562 -0.663246,2.234595 -0.04176,0.129019 -0.04288,0.267716 -0.0031,0.397355 0.03974,0.129678 0.11838,0.244026 0.225322,0.327639 0.07546,0.08338 0.177628,0.137884 0.28899,0.154219 0.111362,0.0163 0.224934,-0.0066 0.321173,-0.06484 z",
          fill: "#212d44",
          id: "path4",
        },
      }),
      _c("path", {
        staticStyle: { "stroke-width": "0.387423" },
        attrs: {
          d: "m 34.066559,26.71728 -3.833414,-3.777293 c 0.567045,-0.602516 1.097643,-1.23151 1.701137,-1.774414 0.233775,-0.183329 0.526138,-0.275922 0.823038,-0.260709 0.296862,0.01521 0.578175,0.137226 0.791902,0.343471 0.722922,0.681949 1.429247,1.383794 2.109051,2.108788 0.21997,0.243329 0.341724,0.559433 0.341724,0.887188 0,0.327793 -0.121754,0.643897 -0.341724,0.887226 -0.06987,0.08323 -0.144049,0.162813 -0.222181,0.238374 z",
          fill: "#212d44",
          id: "path6",
        },
      }),
      _c("path", {
        staticStyle: { "stroke-width": "0.387423" },
        attrs: {
          d: "m 23.262134,24.67964 c 0.307758,-0.307239 0.615476,-0.658336 0.835292,-1.053368 0.39566,-0.658336 0.571505,-1.492221 0.571505,-2.457795 0,-0.746091 -0.131874,-1.404427 -0.439632,-1.931111 -0.307718,-0.526684 -0.659407,-1.009472 -1.143009,-1.360569 -0.483602,-0.351097 -1.011175,-0.658336 -1.58268,-0.877781 -0.571544,-0.219445 -1.187019,-0.394994 -1.758524,-0.526684 -1.011175,-0.219445 -1.714592,-0.438891 -2.154224,-0.614439 -0.439631,-0.175549 -0.659446,-0.482787 -0.659446,-0.921678 0,-0.438891 0.219815,-0.789988 0.615476,-0.965575 0.395699,-0.175548 0.83533,-0.263303 1.318932,-0.263303 0.615475,0 1.230951,0.131652 1.758524,0.394994 0.571505,0.263342 1.055107,0.614439 1.450767,1.009432 l 2.813631,-2.896685 c -0.747349,-0.702233 -1.714553,-1.272775 -2.813631,-1.623911 -1.099078,-0.351097 -2.242126,-0.526645 -3.385175,-0.526645 -0.747349,0 -1.494738,0.08776 -2.242126,0.3072 -0.74735,0.175587 -1.450767,0.482787 -2.066282,0.921678 -0.615475,0.395032 -1.143048,0.965575 -1.538708,1.580014 -0.395661,0.658336 -0.571505,1.448362 -0.571505,2.37004 0,0.702233 0.131873,1.360568 0.39566,1.843356 0.263786,0.526645 0.615476,0.965536 1.055107,1.316672 0.439631,0.351096 0.923233,0.614439 1.450806,0.833884 0.527572,0.219445 1.055106,0.351097 1.62665,0.482787 1.055107,0.263342 1.846466,0.482749 2.330029,0.702194 0.483602,0.219484 0.747389,0.570581 0.747389,1.053369 0,0.482787 -0.219816,0.833884 -0.615476,1.053329 -0.395699,0.175587 -0.791359,0.307239 -1.23099,0.307239 -0.74735,0 -1.450767,-0.175548 -2.110214,-0.482787 -0.659486,-0.351097 -1.23099,-0.74613 -1.670621,-1.228917 l -2.857602,2.984479 c 0.307718,0.307239 0.703417,0.570581 1.055106,0.833923 1.758525,-0.614478 3.692932,-0.965575 5.715243,-0.965575 1.802495,-0.08779 3.517049,0.175549 5.099728,0.702233 z",
          fill: "#212d44",
          id: "path8",
        },
      }),
      _c("path", {
        staticStyle: { "stroke-width": "0.387423" },
        attrs: {
          d: "m 6.203999,29.419333 c 2.681757,-3.028337 7.034136,-5.003345 11.914049,-5.003345 2.90355,0 5.593062,0.686749 7.861207,1.871305 l -0.775501,0.917342 c -2.162792,-0.664723 -4.558351,-1.033084 -7.085706,-1.033084 -4.572195,0 -8.74869,1.228878 -11.914049,3.247782 z",
          fill: "#e7775a",
          id: "path10",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "37",
        height: "37",
        viewBox: "0 0 37 37",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M33.7419 0.527179H2.86694C2.16911 0.516675 1.49173 0.762492 0.963257 1.21834C0.434781 1.67419 0.092029 2.30826 0 3.00008V33.8751C0.0842676 34.5862 0.419174 35.244 0.94458 35.7305C1.46999 36.2171 2.15146 36.5006 2.86694 36.5301H17V24.5301H12V17.5301H17V14.1151C17.0877 12.1496 17.9008 10.2865 19.2821 8.88558C20.6634 7.48466 22.515 6.64541 24.479 6.53011H31V12.5301H25.1C24.422 12.5301 24 13.4361 24 14.1151V17.5301H30V24.5301H24V36.5301H33.7419C34.3926 36.4753 34.9952 36.1654 35.4182 35.668C35.8412 35.1707 36.0504 34.5261 36 33.8751V3.00008C36.0232 2.68756 35.9823 2.37361 35.8798 2.07747C35.7773 1.78133 35.6154 1.50921 35.4039 1.27791C35.1925 1.04661 34.9359 0.861104 34.6501 0.732501C34.3644 0.603898 34.0553 0.534971 33.7419 0.530108",
          fill: "#4D6FA9",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }